import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import store from "store";
import updatePlugin from "store/plugins/update";
import {
  CANCELED_USER,
  CREATED_USER,
  LOGGEDIN_USER,
  LOGGEDOUT_USER,
  MIGRATED_USER,
  PASSWORD_RESET,
  PASSWORD_RESET_REQUEST,
  REACTIVATED_USER,
  RETRIEVE_USER,
  UPDATED_USER,
  STATUS_USER,
  UPDATE_SETTINGS,
} from "../../common/consts";
import {
  checkInvalidSessionID,
  debug,
  hasValue,
  isAuthenticated,
  sessionLogout,
} from "../../common/helpers";
import ReactGA from "react-ga4";
import * as compareVersions from "compare-versions";

store.addPlugin(updatePlugin);

const userData = (state = {}, action = {}) => {
  debug.log("User Reducers", state, action);
  // const user = store.get("user");
  // let response = {valid: null, failed: null, new: false, code: "", user: {}};

  if (checkInvalidSessionID({ action })) {
    debug.log("***INVALID SESSION ID");
    // const sessionMessages = store.get("session_messages");
    // if (!sessionMessages) {
    //   store.set("session_messages", ["a", "b", "<strong>c</strong>"]);
    // }
    processUser(action, true);
  }

  switch (action.type) {
    case CREATED_USER:
      // console.log("create user", action);
      debug.log("create user", action);

      return {
        ...state,
        ...processUser(action, true),
        isAuthenticated,
      };

    case UPDATED_USER:
      // console.log("updated user", action);
      debug.log("updated user", action);
      return {
        ...state,
        ...processUser(action, true),
        isAuthenticated,
      };

    case CANCELED_USER:
      debug.log("cancel", action);

      return {
        ...state,
        // user: {},
        // valid: null,
        ...processUser(action),
        isAuthenticated,
      };

    case REACTIVATED_USER:
      debug.log("reactivate", action);

      return {
        ...state,
        // user: {},
        // valid: null,
        ...processUser(action),
        isAuthenticated,
      };

    case MIGRATED_USER:
      debug.log("migrate", action);

      return {
        ...state,
        // user: {},
        // valid: null,
        ...processUser(action),
        isAuthenticated,
      };

    case PASSWORD_RESET_REQUEST:
      //console.log("password request reset", action);
      debug.log("password reset request", action);
      const reset_password_request =
        action.criteria.result === "success" &&
        get(action.criteria, "response.reset_password_request") === true;

      if (reset_password_request) {
        // remove any stored user
        // store.remove("user");
        // store.remove("session_id");
        sessionLogout();
      }

      const reset_password_request_failed_code =
        action.criteria.result === "failed"
          ? get(action.criteria, "response.code")
          : "";

      return {
        ...state,
        reset_password_request,
        reset_password_request_failed_code,
      };

    case PASSWORD_RESET:
      // console.log("password reset", action);
      debug.log("password reset", action);
      const reset_password =
        action.criteria.result === "success" &&
        get(action.criteria, "response.reset_password") === true;

      if (reset_password) {
        // remove any stored user
        // store.remove("user");
        // store.remove("session_id");
        sessionLogout();
      }

      return {
        ...state,
        reset_password,
      };

    case LOGGEDIN_USER:
      //console.log("user login", action);
      debug.log("user login", action);
      //console.log("get", get(action.criteria, "user.uid"));

      /*if (action.criteria.result === "success" && get(action.criteria, "user.uid") > 0) {
        response.valid = true;
        response.failed = false;
        response.user = action.criteria.user;
        //localStorage.setItem("user", JSON.stringify(action.criteria.user));
        store.set("user", action.criteria.user);

      } else {
        response.valid = false;
        response.failed = true;
        //localStorage.removeItem("user");
        store.remove("user");
      }*/

      return {
        ...state,
        ...processUser(action, true),
        isAuthenticated,
      };

    case LOGGEDOUT_USER:
      debug.log("logout", action);
      //process.exit("something");
      return {
        ...state,
        // user: {},
        // valid: null,
        ...processUser(action),
        isAuthenticated,
      };

    case RETRIEVE_USER:
      //console.log("retrieve user reducer: stored user");

      return {
        ...state,
        // ...(!isEmpty(user)) ? user : {},
        // valid: !isEmpty(user),
        ...processUser(action),
        isAuthenticated,
      };

    case STATUS_USER:
      return {
        ...state,
        ...processUser(action),
        isAuthenticated,
      };

    case UPDATE_SETTINGS:
      return {
        ...state,
        isAuthenticated,
      };

    default:
      //console.log("default reducer: stored user", state, action);
      if (
        get(action, "criteria.response") &&
        action.criteria.response.user_status === false
      ) {
        debug.log("****** removing stored user ******", action);
        // store.remove("user");
        // store.remove("session_id");
        sessionLogout();
      }

      if (get(action, "criteria.response")) {
        if (
          hasValue(action.criteria.response.user_locked) &&
          store.get("user")
        ) {
          store.update("user", (user) => {
            user.locked = action.criteria.response.user_locked;
          });
        }

        // if (action.criteria.response.code === "invalid_session_id") {
        //   debug.log("***INVALID SESSION ID");
        //   return {
        //     ...state,
        //     ...processUser("", true)
        //   };
        // }
      }
      //console.log("after removed stored user");

      return {
        ...state,
        //valid: !isEmpty(user),
        //new: false,
        //...(!isEmpty(user)) ? user : {},
        ...processUser({ type: "RETRIEVE USER" }),
        //locked: get(action, "criteria.response.user_locked") && action.criteria.response.user_locked === true,
        isAuthenticated,
      };
  }

  function processUser(action, storeUser = false) {
    debug.log("process user", action);
    let response = {
      valid: null,
      failed: null,
      new: false,
      code: "",
      locked: false,
    };
    const user = store.get("user");

    switch (action.type) {
      case "RETRIEVE USER":
        //console.log("default/retrieve", user);
        if (!isEmpty(user)) {
          response = { ...response, ...user };
          response.valid = true;
        } else {
          response.user = {};
          response.valid = false;
        }
        // console.log("response", response);
        break;

      case "CANCELED USER":
        debug.log("canceled user", action);
        // if (get(action.criteria, "result") && action.criteria.result === "success" && get(action.criteria, "response.code") === "cancel_on_expiration") {
        //   if (process.env.NODE_ENV !== "development") {
        //     ReactGA.event({
        //       category: "User",
        //       action: "Canceled account",
        //       value: user.uid
        //     });
        //   }
        //   // sessionLogout();
        // }

        if (
          get(action.criteria, "result") &&
          action.criteria.result === "success"
        ) {
          if (process.env.NODE_ENV !== "development") {
            ReactGA.event({
              category: "User",
              action: `Canceled account: ${get(
                action.criteria,
                "response.code"
              )}`,
              value: user.uid,
            });
          }

          // if (get(action.criteria, "response.code") === "cancel_on_expiration")
          // if (get(action.criteria, 'response.code') === 'cancel_immediately')
          if (
            get(action.criteria, "response.code") === "cancel_immediately" ||
            get(action.criteria, "response.code") === "cancel_on_expiration"
          ) {
            // force user to re-login to update local user
            // store.remove("user");
            // store.remove("session_id");
            sessionLogout();
          }
        }
        break;

      case "REACTIVATED USER":
        debug.log("reactive user", action);
        if (
          get(action.criteria, "result") &&
          action.criteria.result === "success" &&
          get(action.criteria, "response.code") === "user_reactivated"
        ) {
          if (process.env.NODE_ENV !== "development") {
            ReactGA.event({
              category: "User",
              action: "Reactivated account",
              value: user.uid,
            });
          }
          // force user to re-login to update local user
          // store.remove("user");
          // store.remove("session_id");
          sessionLogout();
        }
        break;

      case "MIGRATED USER":
        debug.log("migrate user", action);
        if (
          get(action.criteria, "result") &&
          action.criteria.result === "success" &&
          get(action.criteria, "response.code") === "user_migrated_subscription"
        ) {
          if (process.env.NODE_ENV !== "development") {
            ReactGA.event({
              category: "User",
              action: "Migrated account",
              value: user.uid,
            });
          }

          response.valid = true;
          response.failed = false;
          response.new = false;
          response.migrated = true;
          response.migrated_message = action.criteria.response.message;
          response = { ...response, ...action.criteria.response.user };
          debug.log("migrated user response", response);
          store.set("user", action.criteria.response.user);

          // force user to re-login to update local user
          // store.remove("user");
          // store.remove("session_id");
          //sessionLogout();
        }
        break;

      case "LOGOUT USER":
        debug.log("logout user", action);

        // if (get(action.criteria, "result") && action.criteria.result === "success" && get(action.criteria, "response.code") === "user_logout") {
        //   store.remove("user");
        // }
        // store.remove("user");
        // store.remove("session_id");
        sessionLogout();
        break;

      case "STATUS USER":
        //debug.log('user status', action);

        // update local user
        store.set("user", {
          ...user,
          locked: action.criteria.response.user_locked,
          status: action.criteria.response.status,
          user_subscriptions: action.criteria.response.user_subscriptions,
        });

        // show message
        // if inactive, logout
        break;

      default:
        if (
          get(action.criteria, "result") &&
          action.criteria.result === "success" &&
          get(action.criteria, "response.user.uid") > 0
        ) {
          debug.log("Updating / Creating User", action);
          response.valid = true;
          response.failed = false;
          // response.new = true;
          response = { ...response, ...action.criteria.response.user };

          if (process.env.NODE_ENV !== "development") {
            ReactGA.event({
              category: "User",
              action: response.new
                ? `Sign Up tier: ${action.criteria.response.user.tier}`
                : "Update User",
              value: action.criteria.response.user.uid,
            });
          }

          if (storeUser) {
            store.set("user", action.criteria.response.user);
          }

          // if this is a free tier user, set the roadblock to true
          if (
            compareVersions(
              action.criteria.response.user.tier.toString(),
              "1.0.0"
            ) < 0
          ) {
            store.set("show_free_tier_roadblock", true);
          }
        } else {
          response.valid = false;
          response.failed = true;

          if (get(action.criteria, "response.code")) {
            response.code = action.criteria.response.code;
          }

          if (get(action.criteria, "response.message.en")) {
            response.message = action.criteria.response.message.en;

            if (response.message.html) {
              const sessionMessages = store.get("session_messages");
              if (!sessionMessages) {
                //store.set("session_messages", ["a", "b", "<strong>c</strong>"]);
                store.set("session_messages", [response.message.html]);
              }
            }
          }

          //response.message = "hello";

          if (storeUser) {
            // store.remove("user");
            // store.remove("session_id");
            sessionLogout();
          }
        }
    }

    const session_id = store.get("session_id");
    if (session_id) {
      response.session_id = session_id;
    }

    //response.migrated_message = "hello";

    debug.log("returning response", response);
    return response;
  }
};

export default userData;
